<template>
  <app-side-bar
    v-model="open"
    :title="'Kompetenzen bearbeiten'"
    order="-2"
  >
    <v-container>
      <v-row>
        <v-col>
          <v-list
            v-model:opened="openPanels"
            density="compact"
          >
            <div v-if="facility && facility.specialities && facility.specialities.length">
              <v-list-group
                v-for="speciality in specialities"
                :key="speciality.id"
                :value="speciality.id"
                fluid
                data-cy="specialitiesList"
              >
                <template #activator="{ props }">
                  <v-list-item
                    v-bind="props"
                    data-cy="specialityName"
                  >
                    <v-list-item-title class="text-h3">
                      {{ speciality.name }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <v-list-item
                  v-for="competence in speciality.competences"
                  :key="competence.id"
                  :class="!competence.editable ? 'cursor-pointer' : ''"
                  :ripple="!competence.editable"
                  data-cy="competencesItem"
                  @click.stop="competence.editable ? null : switchCompetence($event, competence)"
                >
                  <v-text-field
                    v-model="competence.name"
                    hide-details="auto"
                    validate-on="input"
                    data-cy="competenceName"
                    :class="!competence.editable ? 'cursor-pointer' : ''"
                    :readonly="!competence.editable"
                    :variant="competence.editable ? 'outlined' : null"
                    :rules="
                      competence.editable
                        ? [
                            rules.noEmptyString,
                            rules.checkDuplicates(speciality, competence.name, competence.id),
                          ]
                        : []
                    "
                    density="compact"
                    @keydown.enter.stop="
                      competence.editable
                        ? updateCompetence($event, competence, speciality)
                        : switchCompetence($event, competence)
                    "
                    @keydown.stop="
                      competence.editable ? null : switchCompetence($event, competence, speciality)
                    "
                  >
                    <template #prepend>
                      <v-checkbox-btn
                        class="center-align-select"
                        :model-value="
                          competence.facilities.length && competence.facilities[0].active
                        "
                        @click.stop="
                          competence.editable ? null : switchCompetence($event, competence)
                        "
                      />
                    </template>
                    <template #append-inner>
                      <v-icon
                        v-if="!competence.editable"
                        color="primary-darken"
                        data-cy="editCompetence"
                        @click.stop="competence.editable = true"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        v-else
                        color="success"
                        data-cy="successIconCompetence"
                        @click.stop="updateCompetence($event, competence, speciality)"
                      >
                        mdi-check-bold
                      </v-icon>
                    </template>
                    <template #append>
                      <v-icon
                        color="error"
                        size="large"
                        data-cy="deleteCompetence"
                        @click.stop="deleteCompetence(competence.id)"
                      >
                        mdi-close-circle-outline
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="speciality.newCompetence"
                    density="compact"
                    validate-on="input"
                    class="mt-4"
                    variant="outlined"
                    label="Kompetenz hinzufügen"
                    data-cy="newCompetenceName"
                    append-icon="mdi-plus-circle"
                    :rules="[
                      rules.checkDuplicates(speciality, speciality.newCompetence),
                      rules.noEmptyString,
                    ]"
                    @keyup.enter="addCompetence(speciality)"
                    @click:append="addCompetence(speciality)"
                  />
                </v-list-item>
              </v-list-group>
            </div>
            <v-list-item v-else>
              <v-list-item-title>Keine Fachrichtung angegeben</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </app-side-bar>
</template>
<script>
export default {
  data() {
    return {
      facility: null,
      specialities: [],
      competences: [],
      facilityId: null,
      facilityImage: null,
      openPanels: [true, false],
      rules: {
        checkDuplicates: (speciality, value, id = null) => {
          if (
            value &&
            value.length &&
            ((id &&
              speciality.competences
                .filter((comp) => comp.id != id)
                .some((comp) => comp.name == value)) ||
              (id === null &&
                speciality.competences.some((competence) => competence.name == value)))
          ) {
            return 'Eine Kompetenz mit diesem Namen ist bereits vorhanden'
          }
          return true
        },
        noEmptyString: (value) => {
          if (value && value.length && value.match(/^\s+$/)) {
            return 'Der Kompetenzname darf nicht nur aus Leerzeichen bestehen'
          }
          return true
        },
      },
      defaultImage:
        'https://images.unsplash.com/photo-1586773860418-d37222d8fce3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2073&q=80',
    }
  },
  computed: {
    open: {
      get() {
        return this.$sidebarStore.competence.active
      },
      set(newValue) {
        if (!newValue) {
          this.$sidebarStore.close('competence')
        }
      },
    },
  },
  async mounted() {
    this.facilityId = this.$sidebarStore.competence.facility.id
    this.facilityImage = this.$sidebarStore.competence.image || this.defaultImage
    if (this.facilityId) {
      this.facility = await this.$cms.request(
        this.$readItem('facility', this.facilityId, {
          fields: ['id', 'specialities.speciality_id.id', 'specialities.speciality_id.name'],
        })
      )
    }
    if (this.facility.specialities && this.facility.specialities.length) {
      this.openPanels = [this.facility.specialities[0].speciality_id.id]
    }
    this.loadData()
  },
  methods: {
    async loadData() {
      if (this.facility && this.facility.specialities && this.facility.specialities.length) {
        const specialities = this.facility.specialities.map(
          (speciality) => speciality.speciality_id
        )
        const competences = await this.$cms
          .request(
            this.$readItems('competence', {
              filter: {
                speciality: {
                  _in: specialities.map((speciality) => speciality.id),
                },
              },
              deep: {
                facilities: {
                  _filter: {
                    facility_id: {
                      _eq: this.facility.id,
                    },
                  },
                },
              },
              fields: [
                'id',
                'name',
                'speciality',
                'facilities.id',
                'facilities.facility_id',
                'facilities.active',
              ],
              sort: 'date_created',
            })
          )
          .then((response) => {
            return response
          })
        this.specialities = specialities.map((speciality) => {
          return {
            ...speciality,
            competences: competences
              .filter((competence) => competence.speciality === speciality.id)
              .map((competence) => {
                return {
                  ...competence,
                  editable: false,
                  active: competence.facilities.length && competence.facilities[0].active,
                }
              }),
          }
        })
      }
    },
    async addCompetence(speciality) {
      if (
        speciality.newCompetence &&
        speciality.newCompetence.length &&
        !speciality.newCompetence.match(/^\s+$/)
      ) {
        // Check if a speciality with this name already exists, if so toggle it active
        if (
          speciality.competences.some((competence) => competence.name == speciality.newCompetence)
        ) {
          const comp_fac = speciality.competences.filter(
            (competence) => competence.name == speciality.newCompetence
          )[0].facilities[0]
          comp_fac.active = true
          await this.$cms.request(
            this.$updateItem('competence_facility', comp_fac.id, {
              active: true,
            })
          )
          speciality.newCompetence = ''
          return
        }
        await this.$cms.request(
          this.$createItem('competence', {
            name: speciality.newCompetence,
            speciality: speciality.id,
            facilities: [{ facility_id: this.facilityId, active: true }],
          })
        )
        speciality.newCompetence = ''
        this.loadData()
      }
    },
    async switchCompetence(e, competence) {
      e.stopPropagation()
      if (!competence.facilities.length) {
        await this.$cms.request(
          this.$createItem('competence_facility', {
            facility_id: this.facilityId,
            competence_id: competence.id,
            active: true,
          })
        )
      } else {
        await this.$cms.request(
          this.$updateItem('competence_facility', competence.facilities[0].id, {
            active: !competence.facilities[0].active,
          })
        )
      }
      this.loadData()
    },
    async deleteCompetence(competenceId) {
      await this.$cms.request(this.$deleteItem('competence', competenceId))
      this.loadData()
    },
    async updateCompetence(event, competence, speciality) {
      if (competence.name == '' || competence.name.match(/^\s+$/g)) {
        await this.deleteCompetence(competence.id)
        return
      }
      if (
        speciality.competences.some(
          (comp) => comp.name == competence.name && comp.id != competence.id
        )
      ) {
        const comp_fac = speciality.competences.filter(
          (comp) => comp.name == competence.name && comp.id != competence.id
        )[0].facilities[0]
        if (comp_fac.active != competence.facilities[0].active) {
          comp_fac.active = competence.facilities[0].active
          await this.$cms.request(
            this.$updateItem('competence_facility', comp_fac.id, {
              active: competence.facilities[0].active,
            })
          )
        }
        await this.deleteCompetence(competence.id)
        return
      }
      await this.$cms.request(
        this.$updateItem('competence', competence.id, {
          name: competence.name,
        })
      )
      this.loadData()
      competence.editable = false
    },
  },
}
</script>
<style lang="scss">
.align-center {
  .v-input__prepend {
  }
}
</style>
