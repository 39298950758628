<template>
  <app-side-bar
    v-model="open"
    title="Dienstfähigkeit konfigurieren"
  >
    <v-container>
      <v-row>
        <v-col cols="12"
          ><schedule-flow-requirement-list
            v-if="facilityId"
            collection="facility"
            :item="facilityId"
            :facility-id="facilityId"
            :experience-only="true"
            hide-external
          />
        </v-col>
      </v-row>
    </v-container>
  </app-side-bar>
</template>
<script>
export default {
  data() {
    return {
      facilities: [],
      facilityId: null,
    }
  },
  computed: {
    open: {
      get() {
        return this.$sidebarStore.dutyEdit.active
      },
      set(newValue) {
        if (!newValue) {
          this.$sidebarStore.close('dutyEdit')
        }
      },
    },
  },
  async mounted() {
    this.facilityId = this.$sidebarStore.dutyEdit.facilityId
  },
  methods: {},
}
</script>
