<template>
  <app-side-bar
    v-model="open"
    :image="
      rotationId && externalRotation.origin_facility_id && externalRotation.origin_facility_id.image
        ? externalRotation.origin_facility_id.image
        : defaultImage
    "
    :title="facilityId ? 'externe Einrichtung bearbeiten' : 'externe Einrichtung hinzufügen'"
  >
    <template #actions>
      <v-btn
        v-if="rotationId"
        icon
        :loading="deleteLoading"
        data-cy="deleteRotation"
        color="white"
        @click="deleteBanner = true"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>

      <v-btn
        data-cy="saveRotation"
        icon
        class="mr-4"
        color="white"
        @click="saveRotation"
      >
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </template>
    <v-expand-transition>
      <v-banner
        v-if="deleteBanner"
        class="bg-error"
        icon="mdi-alert"
        color="error"
      >
        Möchten Sie diese wirklich löschen?<br />
        <v-btn
          class="mt-2"
          variant="outlined"
          color="white"
          data-cy="confirmDeleteRotation"
          :loading="deleteLoading"
          @click="deleteRotation"
        >
          Ja
        </v-btn>
        <v-btn
          class="mt-2 ml-2"
          variant="outlined"
          color="white"
          @click="deleteBanner = false"
        >
          Nein
        </v-btn>
      </v-banner>
    </v-expand-transition>

    <v-container>
      <v-row>
        <v-col>
          <select-facility
            v-model="externalRotation.external_facility_id"
            label="externe Einrichtung auswählen"
            :facility-card-color="error ? 'error' : 'primary'"
            @update:model-value="error = false"
          />
        </v-col>
      </v-row>
    </v-container>
  </app-side-bar>
</template>
<script>
export default {
  data() {
    return {
      externalRotation: {},
      deleteLoading: false,
      rotationId: null,
      facilityId: null,
      error: false,
      deleteBanner: false,
      defaultImage:
        'https://images.unsplash.com/photo-1586773860418-d37222d8fce3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2073&q=80',
    }
  },
  computed: {
    open: {
      get() {
        return this.$sidebarStore.external.active
      },
      set(newValue) {
        if (!newValue) {
          this.$sidebarStore.close('external')
        }
      },
    },
  },
  async mounted() {
    this.rotationId = this.$sidebarStore.external.rotation
      ? this.$sidebarStore.external.rotation.id
      : null
    this.facilityId = this.$sidebarStore.external.facility
      ? this.$sidebarStore.external.facility.id
      : null
    if (this.rotationId) {
      this.externalRotation = await this.$cms.request(
        this.$readItem('facility_external_facility', this.rotationId, {
          fields: [
            'origin_facility_id',
            'external_facility_id',
            'rotation_types',
            'origin_facility_id.image',
          ],
        })
      )
      this.fileUrl(this.externalRotation.origin_facility_id.image).then((result) => {
        this.externalRotation.origin_facility_id.image = result
      })
    } else {
      this.externalRotation = { origin_facility_id: this.facilityId }
    }
  },
  methods: {
    async saveRotation() {
      if (this.externalRotation && this.externalRotation.external_facility_id) {
        const externalRotation = {
          external_facility_id: this.externalRotation.external_facility_id,
          origin_facility_id: this.facilityId,
        }
        if (this.rotationId) {
          await this.$cms.request(
            this.$updateItem('facility_external_facility', this.rotationId, externalRotation)
          )
        } else {
          await this.$cms.request(this.$createItem('facility_external_facility', externalRotation))
        }
        this.open = false
      } else {
        this.error = true
      }
    },
    async deleteRotation() {
      this.deleteLoading = true
      await this.$cms.request(this.$deleteItem('facility_external_facility', this.rotationId))
      this.deleteLoading = false
      this.open = false
    },
  },
}
</script>
