<template>
  <app-side-bar
    v-model="open"
    title="Rotationseinstellungen bearbeiten"
    order="-2"
  >
    <template
      v-if="!disabled"
      #actions
    >
      <v-btn
        data-cy="saveFacility"
        icon
        @click="saveFacility"
      >
        <v-icon color="white"> mdi-check </v-icon>
      </v-btn>
    </template>
    <v-container>
      <schedule-settings
        v-if="facilityId"
        ref="scheduleSettings"
        :facility-id="facilityId"
        :disabled="disabled"
      />
      <v-row>
        <v-col class="ml-4 mt-5">
          <v-btn
            data-cy="saveFacilityButton"
            color="primary-darken"
            rounded
            size="large"
            class="px-10"
            @click="saveFacility"
          >
            Speichern
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </app-side-bar>
</template>
<script>
export default {
  data() {
    return {
      facilityId: null,
      facilityImage: null,
      disabled: false,
      external: false,
      defaultImage:
        'https://images.unsplash.com/photo-1586773860418-d37222d8fce3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2073&q=80',
    }
  },
  computed: {
    open: {
      get() {
        return this.$sidebarStore.settings.active
      },
      set(newValue) {
        if (!newValue) {
          this.$sidebarStore.close('settings')
        }
      },
    },
  },
  mounted() {
    this.facilityId = this.$sidebarStore.settings.facility.id
    this.facilityImage = this.$sidebarStore.settings.image || this.defaultImage
    this.disabled = this.$sidebarStore.settings.disabled
  },
  methods: {
    async saveFacility() {
      await this.$refs.scheduleSettings.submit()
      this.open = false
    },
  },
}
</script>
