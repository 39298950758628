const strings = {
  every: 'Alle',
  until: 'endet am',
  day: 'Tag',
  days: 'Tage',
  week: 'Woche',
  weeks: 'Wochen',
  on: 'immer am',
  in: 'in',
  'on the': 'immer am',
  for: 'für',
  and: 'und',
  or: 'oder',
  at: 'bei',
  last: 'zuletzt',
  the: 'den',
  st: '.',
  nd: '.',
  rd: '.',
  th: '.',
  '(~ approximate)': '(~ ungefähr)',
  times: 'Zeiten',
  time: 'Zeit',
  minutes: 'Minuten',
  hours: 'Stunden',
  weekdays: 'Wochentag',
  weekday: 'Wochentag',
  months: 'Monate',
  month: 'Monate',
  years: 'Jahre',
  year: 'Jahr',
}
export default {
  getText: (id) => strings[id] || id,
  language: {
    dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    monthNames: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
  },
  dateFormat: (year, month, day) => `${day}. ${month} ${year}`,
}
