<template>
  <div>
    <!-- Check if the current page is a dashboard started. 0 is index of string 'dashboard-' -->
    <v-app
      :class="{
        fluidtitle: true,
        background: true,
        dashboard: $route.name.search('dashboard-') === 0 && !hasRole(['ROTATING_STAFF']),
        default: $route.name.search('dashboard-') !== 0 || hasRole(['ROTATING_STAFF']),
      }"
    >
      <app-administrative-Navigation
        :show-menu="showMenu"
        @toggle-menu="toggleMenu"
      />
      <app-notification />
      <v-slide-y-reverse-transition>
        <div
          v-if="showMenu"
          class="overlay overflow-auto"
        >
          <app-navigation-trainee
            class="mt-15"
            @toggle-menu="toggleMenu"
          />
        </div>
      </v-slide-y-reverse-transition>
      <v-main class="mt-5">
        <slot />
      </v-main>
      <facility-sidebar-overview v-if="isOpen('overview')" />
      <facility-sidebar-edit v-if="isOpen('edit')" />
      <sidebar-rotating-overview v-if="isOpen('rotatingOverview')" />
      <sidebar-administrative-overview v-if="isOpen('administrativeOverview')" />
      <facility-sidebar-settings v-if="isOpen('settings')" />
      <facility-sidebar-external v-if="isOpen('external')" />
      <facility-sidebar-competence v-if="isOpen('competence')" />
      <facility-sidebar-unit v-if="isOpen('unit')" />
      <sidebar-label-edit v-if="isOpen('labelEdit')" />
      <sidebar-duty-edit v-if="isOpen('dutyEdit')" />
      <facility-sidebar-unitEdit v-if="isOpen('unitEdit')" />
      <sidebar-TwoFactorAuth v-if="isOpen('twoFactorAuth')" />
      <app-dataprotection-dialog />

      <app-bottom-navigation v-if="$vuetify.display.mdAndDown && hasRole(['ROTATING_STAFF'])" />
    </v-app>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mobileBrowser: true,
      updateAvailable: false,
      showMenu: false,
    }
  },
  mounted() {
    this.mobileBrowser = this.$vuetify.display.mobile && !this.isNativePlatform
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    isOpen(type) {
      switch (type) {
        case 'overview':
          return this.$sidebarStore.overview.active
        case 'edit':
          return this.$sidebarStore.edit.active
        case 'rotatingOverview':
          return this.$sidebarStore.rotatingOverview.active
        case 'administrativeOverview':
          return this.$sidebarStore.administrativeOverview.active
        case 'settings':
          return this.$sidebarStore.settings.active
        case 'external':
          return this.$sidebarStore.external.active
        case 'competence':
          return this.$sidebarStore.competence.active
        case 'unit':
          return this.$sidebarStore.unit.active
        case 'unitEdit':
          return this.$sidebarStore.unitEdit.active
        case 'twoFactorAuth':
          return this.$sidebarStore.twoFactorAuth.active
        case 'labelEdit':
          return this.$sidebarStore.labelEdit.active
        case 'dutyEdit':
          return this.$sidebarStore.dutyEdit.active
        default:
          return false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.background {
  // background: linear-gradient(to bottom right, #5acf62, rgba(255, 153, 150, 0), #296084 97%),
  //    linear-gradient(to top right, white 10%, rgba(255, 153, 150, 0), white) #296084;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;

  &.dashboard {
    background-image: url('/img/background/dashboard.png');
  }
  &.default {
    background-image: url('/img/background/default.png');
  }
}
.overlay {
  position: fixed;
  top: 0;
  background-color: rgba(7, 19, 59, 0.9);
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 1005;
}
@keyframes slideUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}
#staff {
  margin: 0 auto;
}
</style>
