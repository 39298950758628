<template>
  <app-side-bar
    v-model="open"
    :title="formatUserName(user)"
  >
    <template #actions>
      <v-btn
        icon
        data-cy="editCheif"
        :to="`/network/profile/administrative/${user.id}`"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-container>
      <span class="text-subtitle-1"> Kontaktdaten </span>
      <v-hover v-slot="{ isHovering, props }">
        <v-list-item
          :to="`/network/profile/administrative/${user.id}?active=Contact`"
          lines="two"
          v-bind="props"
          class="mb-4"
        >
          <template #prepend>
            <profile-photo
              class="mr-4"
              :user-id="user.id"
              readonly
              :size="50"
            />
          </template>
          <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          <v-list-item-subtitle>{{ user.phone_number }}</v-list-item-subtitle>
          <template #append>
            <v-icon
              v-if="isHovering"
              size="small"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-list-item>
      </v-hover>

      <span class="text-subtitle-1"> Verwaltung </span>
      <v-hover v-slot="{ isHovering, props }">
        <v-list-item
          :to="`/network/profile/administrative/${user.id}?active=Permissions`"
          v-bind="props"
          class="mb-4"
        >
          <v-list-item-subtitle v-if="user.main_facility_admin">
            Krankenhausweite Verwaltung
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-for="facility in user.facilities.filter((f) => f.type === 'default')"
            v-else-if="user.facilities?.filter((f) => f.type === 'default').length"
            :key="facility.id"
          >
            {{
              formatParts([facility.facility_id?.parent_facility?.name, facility.facility_id?.name])
            }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else>-</v-list-item-subtitle>

          <template #append>
            <v-icon
              v-if="isHovering"
              size="small"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-list-item>
      </v-hover>

      <span class="text-subtitle-1"> Koordination </span>
      <v-hover v-slot="{ isHovering, props }">
        <v-list-item
          :to="`/network/profile/administrative/${user.id}?active=Permissions`"
          v-bind="props"
          class="mb-4"
        >
          <template v-if="user.facilities?.filter((f) => f.type === 'coordinate').length">
            <v-list-item-subtitle
              v-for="facility in user.facilities.filter((f) => f.type === 'coordinate')"
              :key="facility.id"
            >
              {{
                formatParts([
                  facility.facility_id?.parent_facility?.name,
                  facility.facility_id?.name,
                ])
              }}
            </v-list-item-subtitle>
          </template>
          <v-list-item-subtitle v-else>-</v-list-item-subtitle>

          <template #append>
            <v-icon
              v-if="isHovering"
              size="small"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-list-item>
      </v-hover>

      <span class="text-subtitle-1"> Weiterbildungsermächtigung </span>
      <profile-authorisation
        v-if="user.id"
        :user-id="user.id"
        readonly
      />
    </v-container>
  </app-side-bar>
</template>
<script>
export default {
  data() {
    return {
      user: {},
      facilityName: 'Keine Klinik agegeben',
    }
  },
  computed: {
    open: {
      get() {
        return this.$sidebarStore.administrativeOverview.active
      },
      set(newValue) {
        if (!newValue) {
          this.$sidebarStore.close('administrativeOverview')
        }
      },
    },
  },
  async mounted() {
    this.user = this.$sidebarStore.administrativeOverview.user
    const user = await this.$cms.request(
      this.$readUser(this.user.id, {
        fields: [
          'id',
          'main_facility_admin',
          'facilities.id',
          'facilities.type',
          'facilities.facility_id.name',
          'facilities.facility_id.parent_facility.name',
        ],
      })
    )
    if (user.facility) {
      this.facilityName = user.facility.name
    }
  },
  methods: {},
}
</script>
