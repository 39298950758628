<template>
  <app-side-bar v-model="open">
    <template #actions>
      <app-assistant-button
        v-if="isOwnFacility(facility.id)"
        id="facility-assistant"
        :slides="slides"
        name="Klinikassistent"
        color="primary-darken"
        @finish="loadData"
      />
      <v-menu v-if="isOwnFacility(facility.id)">
        <template #activator="{ props }">
          <v-btn
            data-cy="dots"
            icon
            v-bind="props"
          >
            <v-icon> mdi-dots-vertical </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-if="selectedFacility === facility.id"
            prepend-icon="mdi-close"
            title="Abwählen"
            @click="$facilityStore.$reset()"
          />
          <v-list-item
            v-else
            prepend-icon="mdi-check"
            title="Auswählen"
            @click="setFacility(facility)"
          />
          <v-list-item
            data-cy="add_button"
            prepend-icon="mdi-plus"
            title="Neue untergeordnete Einrichtung"
            @click="editFacility({ parentFacility: { id: facility.id } })"
          />
          <v-list-item
            v-if="
              currentUser.main_facility_admin &&
              facility.child_facilities &&
              !facility.child_facilities.length
            "
            data-cy="delete_button"
            prepend-icon="mdi-delete"
            title="Löschen"
            @click="deleteBanner = true"
          />
        </v-list>
      </v-menu>
    </template>
    <template #headerTitle>
      <v-toolbar-title>
        <div class="text-truncate text-subtitle-1 pa-0">
          {{ formatFacilityHierarchy(facility) }}
        </div>
        <div data-cy="overviewFaciltyName">
          {{ facility.name }}
        </div>
      </v-toolbar-title>
    </template>
    <v-expand-transition>
      <v-banner
        v-if="deleteBanner"
        color="error"
        class="bg-error"
        icon="mdi-alert"
      >
        Möchten Sie diese Organisation wirklich löschen?<br />
        Alle damit verknüpften Daten gehen unwiderruflich verloren.<br />
        <v-btn
          data-cy="deleteFacility"
          class="mt-2"
          variant="outlined"
          :loading="deleteLoading"
          @click="deleteFacility(facility.id)"
        >
          Ja
        </v-btn>
        <v-btn
          class="mt-2 ml-2"
          variant="outlined"
          @click="deleteBanner = false"
        >
          Nein
        </v-btn>
      </v-banner>
    </v-expand-transition>
    <v-container>
      <v-list>
        <span class="text-subtitle-1"> Allgemeine Informationen </span>
        <v-hover v-slot="{ isHovering }">
          <v-list-item
            data-cy="editFacility"
            @click="
              isOwnFacility(facility.id) ? editFacility({ facility: { id: facility.id } }) : null
            "
          >
            <v-list-item-subtitle v-if="!facility.name">
              Keine Adresse angegeben
            </v-list-item-subtitle>
            <div v-else>
              <v-list-item-subtitle> Name: {{ facility.name }}</v-list-item-subtitle>
              <v-list-item-subtitle> Abkürzung: {{ getShortName(facility) }} </v-list-item-subtitle>
              <v-list-item-subtitle>
                In Breadcrumb anzeigen: {{ facility.show_in_hierarchy ? 'Ja' : 'Nein' }}
              </v-list-item-subtitle>
            </div>
            <template #append>
              <v-icon
                v-if="isOwnFacility(facility.id) && isHovering"
                size="small"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-list-item>
        </v-hover>
        <div v-if="isOwnFacility(facility.id)">
          <v-divider class="my-2" />
          <span class="text-subtitle-1"> Rotationseinstellungen </span>
          <v-hover v-slot="{ isHovering }">
            <v-list-item
              data-cy="rotationSettings"
              @click="rotationSettings(facility)"
            >
              <v-list-item-subtitle v-if="facility.schedule_rrule">
                Standardrotation: {{ ruleText(facility.schedule_rrule) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-else
                class="text-grey"
              >
                kein Rotationsintervall festgelegt
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="facility.external_schedule_rrule">
                Rotation: {{ ruleText(facility.external_schedule_rrule) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="facility.external_type === 'receive_only'">
                Erhält nur Rotanden
              </v-list-item-subtitle>
              <template #append>
                <v-icon
                  v-if="isHovering"
                  size="small"
                >
                  mdi-pencil
                </v-icon>
              </template>
            </v-list-item>
          </v-hover>

          <v-divider class="my-2" />
          <span class="text-subtitle-1"> Rotationscurricula </span>

          <v-hover v-slot="{ isHovering }">
            <v-list-item :to="`/schedule/flow/${facility.id}`">
              <v-list-item-subtitle v-if="scheduleFlow">
                Rotationsablauf festgelegt
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="scheduleFlow">
                {{ scheduleFlow.nodes.length }} Rotationsblöcke
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else> Neuen Rotationsablauf erstellen </v-list-item-subtitle>
              <template #append>
                <v-icon
                  v-if="isHovering"
                  size="small"
                >
                  mdi-pencil
                </v-icon>
              </template>
            </v-list-item>
          </v-hover>
          <template v-if="getPremiumFeatures().includes('COMPETENCES')">
            <v-divider class="my-2" />
            <span class="text-subtitle-1"> Kompetenzkatalog </span>

            <v-hover v-slot="{ isHovering }">
              <v-list-item
                data-cy="overviewCompetence"
                @click="competenceFacility(facility)"
              >
                <v-list-item-subtitle v-if="competences.length">
                  {{ formatParts(competences, ', ') }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-else
                  class="text-grey"
                >
                  Keine Kompetenzen angegeben
                </v-list-item-subtitle>
                <template #append>
                  <v-icon
                    v-if="isHovering"
                    size="small"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
              </v-list-item>
            </v-hover>
          </template>

          <v-divider class="my-2" />
          <span class="text-subtitle-1"> verwaltendes Personal </span>
          <div v-if="administrativeStaff && administrativeStaff.length">
            <v-list-item
              v-for="administrativeUser in administrativeStaff"
              :key="'sidebar-' + administrativeUser.id"
              :to="'/network/profile/administrative/' + administrativeUser.id"
            >
              <template #prepend>
                <profile-photo
                  :user-id="administrativeUser.id"
                  data-cy="avatar"
                  readonly
                  :size="40"
                  class="mr-3"
                />
              </template>

              <v-list-item-subtitle>
                {{ formatUserName(administrativeUser) }}
              </v-list-item-subtitle>
            </v-list-item>
          </div>
          <v-list-item v-else>
            <v-list-item-title class="text-grey">
              Kein verwaltendes Personal gefunden
            </v-list-item-title>
          </v-list-item>

          <v-divider class="my-2" />
          <span class="text-subtitle-1"> koordinierendes Personal </span>
          <div v-if="coordinatingStaff && coordinatingStaff.length">
            <v-list-item
              v-for="user in coordinatingStaff"
              :key="'sidebar-' + user.id"
              :to="'/network/profile/administrative/' + user.id"
            >
              <template #prepend>
                <profile-photo
                  :user-id="user.id"
                  data-cy="avatar"
                  readonly
                  :size="40"
                  class="mr-3"
                />
              </template>

              <v-list-item-subtitle>
                {{ formatUserName(user) }}
              </v-list-item-subtitle>
            </v-list-item>
          </div>
          <v-list-item v-else>
            <v-list-item-title class="text-grey">
              Kein koordinierendes Personal gefunden
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-container>
  </app-side-bar>
</template>
<script>
import { RRule } from 'rrule'
import helper from '../../rrulehelper'

export default {
  setup() {
    return {}
  },
  data() {
    return {
      value: null,
      facility: {},
      scheduleFlow: null,
      competences: [],
      entries: [],
      deleteBanner: false,
      deleteLoading: false,
      defaultImage:
        'https://images.unsplash.com/photo-1586773860418-d37222d8fce3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2073&q=80',
    }
  },
  computed: {
    open: {
      get() {
        return this.$sidebarStore.overview.active
      },
      set(newValue) {
        if (!newValue) {
          this.$sidebarStore.close('overview')
        }
      },
    },
    administrativeStaff() {
      const users = this.facility.users
        .filter((user) => user.type === 'default' && user.directus_users_id)
        .map((user) => user.directus_users_id)
      return users
    },
    coordinatingStaff() {
      const users = this.facility.users
        .filter((user) => user.type === 'coordinate' && user.directus_users_id)
        .map((user) => user.directus_users_id)
      return users
    },
    slides() {
      if (this.facility && this.facility.id) {
        return [
          {
            title: 'Einheiten',
            description:
              'Tragen Sie alle Rotationsbereiche mit Kapazitäten dieser Einrichtung ein. In diese so genannten Einheiten werden Rotanden bei Rotationsvorschlägen eingeteilt.',
            component: markRaw(resolveComponent('app-bulk-edit-collection')),
            fluid: true,
            props: {
              dense: true,
              collection: 'unit',
              filter: {
                facility: {
                  _eq: this.facility.id,
                },
              },
              headers: [
                {
                  title: 'Kürzel',
                  key: 'short_name',
                  align: 'left',
                  sortable: true,
                  synonyms: ['kurzer name'],
                  width: 200,
                  rules: [(v) => !!v],
                },
                {
                  title: 'Name',
                  key: 'long_name',
                  align: 'left',
                  sortable: true,
                  synonyms: ['langer name'],
                  rules: [(v) => !!v],
                },

                {
                  title: 'Min.',
                  key: 'minimum_vacancies',
                  align: 'left',
                  sortable: true,
                  hint: 'Geben Sie die Minimalbesetzung dieser Einheit an. Bei Rotationsvorschlägen wird versucht, alle Einheiten mit dieser Besetzung auszulasten.',
                  synonyms: ['minimalbesetzung', 'minimum', 'besetzung'],
                  inputProps: {
                    type: 'number',
                    step: '0.1',
                  },
                  width: 100,
                  rules: [(v) => v != null],
                },
                {
                  title: 'Max.',
                  key: 'maximum_vacancies',
                  align: 'left',
                  sortable: true,
                  hint: 'Geben Sie die Maximalbesetzung dieser Einheit an. So kann diese Einheit im Rotationsvorschlag optional über die Minimalbesetung ausgelastet werden.',
                  synonyms: ['maximalbesetzung', 'maximum'],
                  inputProps: {
                    type: 'number',
                    step: '0.1',
                  },
                  width: 100,
                  rules: [(v) => v != null],
                },
                {
                  title: 'Fachrichtung',
                  key: 'specialities',
                  align: 'left',
                  sortable: true,
                  hide: true,
                  hint: 'Geben Sie an, welcher Fachbereich von dieser Einheit in der ärztlichen Weiterbildung abgedeckt wird. Diese wird für die Berechnung des Fortschritts mit der Weiterbildungsordnung abgeglichen.',
                  fields: ['speciality_id'],
                  synonyms: ['richtung', 'fach'],
                  inputComponent: markRaw(resolveComponent('select-speciality')),
                  inputProps: {
                    multiple: true,
                    fieldName: 'speciality_id',
                  },
                  width: 250,
                },
                {
                  title: 'Rotationstyp',
                  key: 'rotation_types',
                  align: 'left',
                  sortable: true,
                  hide: true,
                  hint: 'Geben Sie den Rotationstyp dieser Einheit an. Dieser wird zur Erstellung des Rotationscurriculum verwendet.',
                  synonyms: ['typ'],
                  inputComponent: markRaw(resolveComponent('select-rotation-type')),
                  inputProps: {
                    facilityId: this.facility.id,
                    chips: true,
                    multiple: true,
                    withDefault: true,
                  },
                  width: 250,
                },
                /*
                {
                  title: 'Prio.',
                  key: 'priority',
                  align: 'left',
                  sortable: true,
                  hide: true,
                  synonyms: ['priorität'],
                  hint: 'Geben Sie optional eine Besetzungpriorität an. Im Rotationsvorschlag werden Einheiten mit hoher Priorität bevorzugt ausgelastet.',
                  inputProps: {
                    type: 'number',
                    step: '1',
                  },
                  width: '130',
                },
                {
                  title: 'extern',
                  key: 'takes_external_rotations',
                  align: 'left',
                  sortable: true,
                  hide: true,
                  hint: 'Geben Sie an, ob externe Rotanden in diese Einheit eingeteilt werden sollen.',
                  synonyms: ['außenrotation', 'zulassen'],
                  inputComponent: markRaw(resolveComponent('v-switch')),
                },
                */
              ],
              transformNewItem: (item) => {
                item.facility = this.facility.id
                return item
              },
              defaultItem: {
                type: this.facility.type,
                takes_external_rotations: true,
              },
            },
            submit: true,
          },
          {
            title: 'Einheiten',
            description: 'Tragen Sie die Weiterbildungsdaten der Einheiten ein.',
            component: markRaw(resolveComponent('app-bulk-edit-collection')),
            props: {
              updateOnly: true,
              collection: 'unit',
              filter: {
                facility: {
                  _eq: this.facility.id,
                },
              },
              headers: [
                {
                  title: 'Kürzel',
                  key: 'short_name',
                  align: 'left',
                  sortable: true,
                  synonyms: ['kurzer name'],
                  rules: [(v) => !!v],
                  readOnly: true,
                  width: '100',
                },
                {
                  title: 'Name',
                  key: 'long_name',
                  align: 'left',
                  sortable: true,
                  synonyms: ['langer name'],
                  rules: [(v) => !!v],
                  readOnly: true,
                },
                {
                  title: 'Fachrichtung',
                  key: 'specialities',
                  align: 'left',
                  sortable: true,
                  hint: 'Geben Sie an, welcher Fachbereich von dieser Einheit in der ärztlichen Weiterbildung abgedeckt wird. Diese wird für die Berechnung des Fortschritts mit der Weiterbildungsordnung abgeglichen.',
                  fields: ['speciality_id'],
                  synonyms: ['richtung', 'fach'],
                  inputComponent: markRaw(resolveComponent('select-speciality')),
                  inputProps: {
                    multiple: true,
                    fieldName: 'speciality_id',
                  },
                  rules: [(v) => !!v && !!v.length],
                  width: 250,
                },
                /*{
                  title: 'Versorgung',
                  key: 'type',
                  align: 'left',
                  sortable: true,
                  hint: 'Geben Sie die Versorgungsart dieser Einheit an. Diese wird für die Berechnung des Fortschritts mit der Weiterbildungsordnung abgeglichen.',
                  synonyms: ['versorgungstyp'],
                  inputComponent: markRaw(resolveComponent('v-select')),
                  inputProps: {
                    items: [
                      {
                        title: 'stationär',
                        value: 'INPATIENT',
                      },
                      {
                        title: 'ambulant',
                        value: 'OUTPATIENT',
                      },
                    ],
                  },
                },*/
                {
                  title: 'Rotationstyp',
                  key: 'rotation_types',
                  align: 'left',
                  sortable: true,
                  hint: 'Geben Sie den Rotationstyp dieser Einheit an. Dieser wird zur Erstellung des Rotationscurriculum verwendet.',
                  synonyms: ['typ'],
                  inputComponent: markRaw(resolveComponent('select-rotation-type')),
                  inputProps: {
                    facilityId: this.facility.id,
                    chips: true,
                    multiple: true,
                    withDefault: true,
                  },
                  rules: [(v) => !!v && !!v.length],
                  width: 250,
                },
                /*
                {
                  title: 'Prio.',
                  key: 'priority',
                  align: 'left',
                  sortable: true,
                  synonyms: ['priorität'],
                  hint: 'Geben Sie optional eine Besetzungpriorität an. Im Rotationsvorschlag werden Einheiten mit hoher Priorität bevorzugt ausgelastet.',
                  inputProps: {
                    type: 'number',
                    step: '1',
                  },
                  width: '130',
                },
                {
                  title: 'extern',
                  key: 'takes_external_rotations',
                  align: 'left',
                  sortable: true,
                  hint: 'Geben Sie an, ob externe Rotanden in diese Einheit eingeteilt werden sollen.',
                  synonyms: ['außenrotation', 'zulassen'],
                  inputComponent: markRaw(resolveComponent('v-switch')),
                },
                */
              ],
            },
            submit: true,
          },
          {
            title: 'Rotationseinstellungen',
            description:
              'Legen Sie fest, wie oft die Rotanden innerhalb dieser Einrichtung rotieren.',
            dense: true,
            component: markRaw(resolveComponent('schedule-settings')),
            props: {
              facilityId: this.facility.id,
            },
            submit: true,
          },
          {
            title: 'Rotationscurriculum',
            description: 'Legen Sie den Rotationsablauf für diese Einrichtung fest.',
            component: markRaw(resolveComponent('schedule-flow-edit')),
            props: {
              facilityId: this.facility.id,
            },
          },
        ]
      } else {
        return []
      }
    },
  },
  watch: {
    '$sidebarStore.edit.active'(value) {
      if (!value) {
        this.loadData(this.$sidebarStore.overview.facility.id)
      }
    },
    '$sidebarStore.settings.active'(value) {
      if (!value) {
        this.loadData(this.$sidebarStore.overview.facility.id)
      }
    },
    '$sidebarStore.competence.active'(value) {
      if (!value) {
        this.loadData(this.$sidebarStore.overview.facility.id)
      }
    },
  },
  async mounted() {
    const facilityId = this.$sidebarStore.overview.facility.id
    await this.loadData(facilityId)
  },
  methods: {
    async loadData(facilityId) {
      if (facilityId) {
        this.facility = await this.$cms.request(
          this.$readItem('facility', facilityId, {
            fields: [
              'id',
              'name',
              'short_name',
              'show_in_hierarchy',
              'image',
              'parent_facility.id',
              'parent_facility.name',
              'parent_facility.show_in_hierarchy',
              'parent_facility.parent_facility.id',
              'parent_facility.parent_facility.name',
              'parent_facility.parent_facility.show_in_hierarchy',
              'parent_facility.parent_facility.parent_facility.id',
              'parent_facility.parent_facility.parent_facility.name',
              'parent_facility.parent_facility.parent_facility.show_in_hierarchy',
              'parent_facility.parent_facility.parent_facility.parent_facility.id',
              'parent_facility.parent_facility.parent_facility.parent_facility.name',
              'parent_facility.parent_facility.parent_facility.parent_facility.show_in_hierarchy',
              'parent_facility.parent_facility.parent_facility.parent_facility.parent_facility.id',
              'parent_facility.parent_facility.parent_facility.parent_facility.parent_facility.name',
              'parent_facility.parent_facility.parent_facility.parent_facility.parent_facility.show_in_hierarchy',
              'child_facilities.id',
              'address.street',
              'address.street_number',
              'address.zip',
              'address.city',
              'schedule_rrule',
              'external_schedule_rrule',
              'external_type',
              'users.id',
              'users.type',
              'users.directus_users_id.id',
              'users.directus_users_id.email',
              'users.directus_users_id.gender',
              'users.directus_users_id.title',
              'users.directus_users_id.first_name',
              'users.directus_users_id.last_name',
              'specialities.speciality_id',
              'units.id',
            ],
            deep: {
              users: {
                directus_users_id: {
                  _filter: {
                    role: {
                      identifier: {
                        _eq: 'ADMINISTRATIVE_STAFF',
                      },
                    },
                    facilities: {
                      facility_id: {
                        _eq: facilityId,
                      },
                    },
                  },
                },
              },
            },
          })
        )
        const response = await this.$cms.request(
          this.$readItems('schedule_flow', {
            filter: {
              facility: {
                _eq: facilityId,
              },
            },
            fields: ['id', 'nodes.id'],
            deep: {
              nodes: {
                _filter: {
                  type: {
                    _eq: 'rotation',
                  },
                },
              },
            },
          })
        )
        if (response && response.length) {
          this.scheduleFlow = response[0]
        }
        this.fileUrl(this.facility.image).then((result) => {
          this.facility.image = result
        })
        if (this.facility && this.facility.specialities && this.facility.specialities.length) {
          const specialities = this.facility.specialities.map(
            (speciality) => speciality.speciality_id
          )
          const competences = await this.$cms
            .request(
              this.$readItems('competence', {
                filter: {
                  _and: [
                    {
                      speciality: {
                        _in: specialities,
                      },
                    },
                    {
                      facilities: {
                        facility_id: {
                          _eq: facilityId,
                        },
                      },
                    },
                    {
                      facilities: {
                        active: {
                          _eq: true,
                        },
                      },
                    },
                  ],
                },
                deep: {
                  facilities: {
                    _filter: {
                      _and: [
                        {
                          facility_id: {
                            _eq: facilityId,
                          },
                        },
                        {
                          active: {
                            _eq: true,
                          },
                        },
                      ],
                    },
                  },
                },
                fields: [
                  'id',
                  'name',
                  'speciality',
                  'facilities.id',
                  'facilities.facility_id',
                  'facilities.active',
                ],
                sort: 'date_created',
              })
            )
            .then((response) => {
              return response
            })
          this.competences = competences
            .filter((competence) => competence.facilities && competence.facilities.length)
            .map((competence) => competence.name)
        }
        /*
        const historyResponse = await this.$cms.request(
          this.$readItems('schedule_history', {
            filter: {
              _and: [
                {
                  start: {
                    _lte: format(new Date(), 'yyyy-MM-dd'),
                  },
                },
                {
                  end: {
                    _gte: format(new Date(), 'yyyy-MM-dd'),
                  },
                },
                {
                  unit: {
                    facility: {
                      _eq: this.value.id,
                    },
                  },
                },

                {
                  status: {
                    _eq: 'confirmed',
                  },
                },
              ],
            },

            fields: [
              'id',
              'user.id',
              'user.gender',
              'user.title',
              'user.first_name',
              'user.last_name',
              'unit.id',
              'unit.short_name',
              'unit.long_name',
              'unit.facility',
              'rotation_type',
            ],
            sort: ['rotation_type', 'unit'],
          })
        )
        this.entries = historyResponse
        */
      }
    },
    setFacility(facility) {
      this.$facilityStore.id = facility.id
      this.$facilityStore.name = facility.name
    },
    async deleteFacility(facilityId) {
      this.deleteLoading = true
      await this.$cms.request(this.$deleteItem('facility', facilityId))
      this.deleteLoading = false
      this.deleteBanner = false
      this.open = false
    },
    ruleText(facilityRrule) {
      if (facilityRrule) {
        const rrule = RRule.fromString(facilityRrule)
        return rrule.toText(helper.getText, helper.language, helper.dateFormat)
      }
      return ''
    },
    editFacility(value) {
      this.$sidebarStore.open({
        type: 'edit',
        facility: value.facility || {},
        parentFacility: value.parentFacility || {},
        image: this.facility.image,
      })
    },
    rotationSettings(value) {
      this.$sidebarStore.open({
        type: 'settings',
        facility: value,
        disabled: this.currentUser.main_facility_admin ? false : true,
        image: this.facility.image,
      })
    },
    competenceFacility(value) {
      this.$sidebarStore.open({ type: 'competence', facility: value, image: this.facility.image })
    },
    async fileUrl(id) {
      if (id) {
        const token = await this.$cms.getToken()
        return `${this.$config.public.cmsUrl}/assets/${id}?access_token=${token}`
      } else {
        return null
      }
    },
  },
}
</script>
