<template>
  <app-side-bar
    v-model="open"
    title="Labels verwalten"
    order="-2"
  >
    <v-container>
      <v-row>
        <v-col>
          <v-list>
            <div v-if="labels && labels.length">
              <v-list-item
                v-for="label in labels"
                :key="label.id"
                class="pb-3"
              >
                <v-text-field
                  :id="label.id"
                  v-model="label.name"
                  :name="label.id"
                  data-cy="labelName"
                  autocomplete="off"
                  variant="outlined"
                  density="compact"
                  hide-details="auto"
                  :rules="[
                    rules.checkDuplicates(labels, label.name, label.id),
                    rules.noEmptyString,
                  ]"
                  @blur="editLabel(label)"
                >
                  <template #append>
                    <v-btn
                      size="small"
                      variant="flat"
                      icon
                      data-cy="deleteLabel"
                      @click.stop="deleteLabel(label.id)"
                    >
                      <v-icon
                        color="error"
                        size="large"
                      >
                        mdi-close-circle-outline
                      </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-list-item>
            </div>
            <v-list-item v-else>
              <v-list-item-title>Noch keine Labels angegeben</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-text-field
                v-model="newLabel"
                data-cy="newLabelName"
                density="compact"
                hide-details="auto"
                variant="outlined"
                label="Label hinzufügen"
                class="pt-2 mt-0"
                :rules="[rules.checkDuplicates(labels, newLabel), rules.noEmptyString]"
                @keyup.enter="addLabel(newLabel)"
              >
                <template #append>
                  <v-btn
                    size="small"
                    variant="flat"
                    icon
                    data-cy="addNewLabel"
                    @click.stop="addLabel(newLabel)"
                  >
                    <v-icon
                      color="primary"
                      size="large"
                      >mdi-plus-circle-outline
                    </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </app-side-bar>
</template>
<script>
export default {
  data() {
    return {
      facilityId: '',
      labels: [],
      newLabel: '',
      rules: {
        checkDuplicates: (labels, newLabel, id = null) => {
          if (
            labels?.length &&
            newLabel &&
            ((id === null && labels.some((label) => label.name === newLabel)) ||
              (id &&
                labels.filter((label) => label.id !== id).some((label) => label.name === newLabel)))
          ) {
            return 'Ein Label mit diesem Namen ist bereits vorhanden'
          }
          return true
        },
        noEmptyString: (value) => {
          if (value && value.length && value.match(/^\s+$/)) {
            return 'Das Label darf nicht nur aus Leerzeichen bestehen'
          }
          return true
        },
      },
    }
  },
  computed: {
    open: {
      get() {
        return this.$sidebarStore.labelEdit.active
      },
      set(newValue) {
        if (!newValue) {
          this.$sidebarStore.close('labelEdit')
        }
      },
    },
  },
  async mounted() {
    this.facilityId = this.$sidebarStore.labelEdit.facilityId
    await this.loadLabels()
  },
  methods: {
    async loadLabels() {
      this.labels = await this.$cms.request(
        this.$readItems('labels', {
          filter: {
            facility: {
              _eq: this.facilityId,
            },
          },
          fields: ['id', 'name'],
        })
      )
    },
    async addLabel(label) {
      label = label.trim()
      if (label == '') {
        return
      }
      if (this.rules.checkDuplicates(this.labels, label) !== true) {
        return
      }
      const response = await this.$cms.request(
        this.$createItem(
          'labels',
          {
            name: label,
            facility: this.facilityId,
          },
          { fields: ['id', 'name'] }
        )
      )
      this.labels.push(response)
      this.newLabel = ''
    },
    async deleteLabel(id) {
      return this.$cms
        .request(this.$deleteItem('labels', id))
        .then(() => (this.labels = this.labels.filter((label) => label.id != id)))
    },
    async editLabel(label) {
      if (
        this.rules.checkDuplicates(this.labels, label.name, label.id) !== true ||
        this.rules.noEmptyString(label.name) !== true ||
        label.name === ''
      ) {
        await this.deleteLabel(label.id)
      } else {
        await this.$cms.request(
          this.$updateItem('labels', label.id, {
            name: label.name,
          })
        )
      }
    },
  },
}
</script>
