<template>
  <v-container>
    <v-row align="end">
      <v-col cols="12">
        <v-text-field
          v-model="startDateModel"
          data-cy="newStartDate"
          type="date"
          lang="de"
          :readonly="disabled"
          :label="startLabel"
          hide-details="auto"
          variant="outlined"
        />
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="selectedInterval"
          data-cy="selectInterval"
          class="inverted-colors"
          hide-details="auto"
          :readonly="disabled"
          :items="selectItems"
          :label="selectLabel"
          :placeholder="ruleText"
          variant="outlined"
          persistent-placeholder
        />
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-card
        v-show="selectedInterval === 'CUSTOM'"
        class="pa-4"
        elevation="1"
      >
        <v-row>
          <v-col cols="12">
            {{ label }}
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="interval"
              type="number"
              min="1"
              max="100"
              step="1"
              hide-details="auto"
              single-line
              variant="outlined"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="frequency"
              :items="frequencyOptions"
              hide-details="auto"
              single-line
              variant="outlined"
            />
          </v-col>

          <v-col
            v-if="(frequency === rrule('DAILY') && interval === 1) || frequency === rrule('WEEKLY')"
            cols="12"
          >
            <v-btn-toggle
              v-model="weekdays"
              multiple
            >
              <v-btn
                v-for="day in weekdaysOptions"
                :key="day.title + '-option'"
                :model-value="day.value"
                variant="outlined"
              >
                {{ day.title }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col
            v-if="frequency === rrule('MONTHLY') || frequency === rrule('YEARLY')"
            cols="12"
          >
            <v-radio-group v-model="bySelection">
              <v-radio
                :label="`Immer am ${weekOfMonth}. ${dayOfWeekName}${
                  frequency === rrule('YEARLY') ? ' im ' + monthName : ' des Monats'
                }`"
                value="byweekday"
              />
              <v-radio
                :label="`Immer am ${dayOfMonth}. ${
                  frequency === rrule('YEARLY') ? monthName : 'Tag des Monats'
                }`"
                value="bymonthday"
              />
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card>
    </v-expand-transition>
  </v-container>
</template>
<script>
import { getDate, getWeekOfMonth, getDay, getMonth, setDate, format } from 'date-fns'
import { de } from 'date-fns/locale'
import { RRule } from 'rrule'
import helper from '../rrulehelper'

export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Rotation wird wiederholt alle',
    },
    startLabel: {
      type: String,
      default: 'Startdatum',
    },
    selectLabel: {
      type: String,
      default: 'Intervall',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selectedInterval: null,
      rule: null,
      interval: 6,
      frequency: RRule.MONTHLY,
      startDateMenu: false,
      startDate: null,
      weekdaysOptions: [
        { title: 'Mo', value: RRule.MO },
        { title: 'Di', value: RRule.TU },
        { title: 'Mi', value: RRule.WE },
        { title: 'Do', value: RRule.TH },
        { title: 'Fr', value: RRule.FR },
        { title: 'Sa', value: RRule.SA },
        { title: 'So', value: RRule.SU },
      ],
      weekdays: [],
      bySelection: 'byweekday',
      changedData: false,
    }
  },
  computed: {
    startDateModel: {
      get() {
        return this.startDate
      },
      set(value) {
        // check if it is filled to prevent errors on empty date
        if (value && value.length) {
          this.startDate = value
        }
      },
    },
    selectItems() {
      return [
        {
          title: `Alle 6 Monate immer am ${this.weekOfMonth}. ${this.dayOfWeekName} des Monats`,
          value: 'SIX_MONTHLY_WEEKDAY',
        },
        {
          title: `Alle 6 Monate immer am ${this.dayOfMonth}. Tag des Monats`,
          value: 'SIX_MONTHLY_DAY',
        },
        {
          title: `Alle 3 Monate immer am ${this.weekOfMonth}. ${this.dayOfWeekName} des Monats`,
          value: 'THREE_MONTHLY_WEEKDAY',
        },
        {
          title: `Alle 3 Monate immer am ${this.dayOfMonth}. Tag des Monats`,
          value: 'THREE_MONTHLY_DAY',
        },
        { title: 'Benutzerdefiniert', value: 'CUSTOM' },
      ]
    },
    frequencyOptions() {
      return [
        { title: this.interval === 1 ? 'Tag' : 'Tage', value: RRule.DAILY },
        { title: this.interval === 1 ? 'Woche' : 'Wochen', value: RRule.WEEKLY },
        { title: this.interval === 1 ? 'Monat' : 'Monate', value: RRule.MONTHLY },
        { title: this.interval === 1 ? 'Jahr' : 'Jahre', value: RRule.YEARLY },
      ]
    },
    ruleText() {
      if (this.rule) {
        let text = this.rule.toText(helper.getText, helper.language, helper.dateFormat)
        if (this.frequency === RRule.MONTHLY && this.bySelection === 'byweekday') {
          text = text + ' des Monats'
        } else if (this.frequency === RRule.MONTHLY && this.bySelection === 'bymonthday') {
          text = text + ' Tag des Monats'
        } else if (this.frequency === RRule.YEARLY) {
          text = text + (this.bySelection === 'byweekday' ? ' im ' : ' ') + this.monthName
          text = text.replace(this.monthName + ' ', '')
        }
        return text
      } else {
        return ''
      }
    },
    month() {
      return getMonth(new Date(this.startDate))
    },
    monthName() {
      return format(new Date(this.startDate), 'MMMM', { locale: de })
    },
    dayOfMonth() {
      return getDate(new Date(this.startDate))
    },
    weekOfMonth() {
      const dayOfWeek = getDay(new Date(this.startDate))
      const startOfMonth = setDate(new Date(this.startDate), 0)
      const firstDayOfWeek = getDay(startOfMonth)
      let weekOfMonth = getWeekOfMonth(new Date(this.startDate), { weekStartsOn: 1, locale: de })
      if (firstDayOfWeek >= dayOfWeek) {
        weekOfMonth = weekOfMonth - 1
      }
      return weekOfMonth
    },
    dayOfWeek() {
      return getDay(new Date(this.startDate))
    },
    dayOfWeekName() {
      return format(new Date(this.startDate), 'EEEE', { locale: de })
    },
  },
  watch: {
    modelValue() {
      this.setRuleFromValue()
    },
    interval() {
      this.setRuleFromData()
    },
    frequency() {
      this.setRuleFromData()
    },
    startDate() {
      this.setRuleFromData()
    },
    weekdays() {
      this.setRuleFromData()
    },
    bySelection() {
      this.setRuleFromData()
    },
    selectedInterval() {
      this.setFromSelection()
    },
  },
  mounted() {
    if (this.modelValue) {
      this.setRuleFromValue()
    } else {
      this.setRuleFromData()
    }
  },
  methods: {
    setStartDate(date) {
      if (date) {
        this.startDate = format(date, 'yyyy-MM-dd')
      }
    },
    setRuleFromData() {
      if (this.startDate && this.startDate.length) {
        this.changedData = true
        const rrule = {
          freq: this.frequency,
          interval: this.interval,
          dtstart: new Date(this.startDate),
        }
        if (this.frequency === RRule.DAILY || this.frequency === RRule.WEEKLY) {
          rrule.byweekday = this.weekdays
        } else {
          if (this.frequency === RRule.YEARLY) {
            rrule.bymonth = [this.month + 1]
          }
          if (this.bySelection === 'bymonthday') {
            rrule.bymonthday = [this.dayOfMonth]
          } else {
            let dayOfWeek = this.dayOfWeek - 1
            if (dayOfWeek < 0) {
              dayOfWeek = 6
            }
            rrule.byweekday = [this.weekdaysOptions[dayOfWeek].value.nth(this.weekOfMonth)]
          }
        }
        this.rule = new RRule(rrule)
        const ruleString = RRule.optionsToString(rrule)
        this.$emit('update:modelValue', ruleString)
      }
    },
    setRuleFromValue() {
      if (!this.changedData) {
        if (this.modelValue) {
          this.rule = RRule.fromString(this.modelValue)
          const options = this.rule.options
          this.frequency = options.freq
          this.interval = options.interval
          this.startDate = options.dtstart.toISOString().substring(0, 10)
          if (this.frequency === RRule.DAILY || this.frequency === RRule.WEEKLY) {
            this.weekdays = options.byweekday.map((weekday) => this.weekdaysOptions[weekday].value)
          } else if (options.bymonthday && options.bymonthday.length) {
            this.bySelection = 'bymonthday'
          } else {
            this.bySelection = 'byweekday'
          }
        }
      }
      this.changedData = false
    },
    setFromSelection() {
      if (this.selectedInterval !== 'CUSTOM' && this.selectedInterval !== 'CURRENT') {
        this.frequency = RRule.MONTHLY
        this.weekdays = []
        switch (this.selectedInterval) {
          case 'SIX_MONTHLY_WEEKDAY':
            this.interval = 6
            this.bySelection = 'byweekday'
            break
          case 'SIX_MONTHLY_DAY':
            this.interval = 6
            this.bySelection = 'bymonthday'
            break
          case 'THREE_MONTHLY_WEEKDAY':
            this.interval = 3
            this.bySelection = 'byweekday'
            break
          case 'THREE_MONTHLY_DAY':
            this.interval = 3
            this.bySelection = 'bymonthday'
            break
        }
      }
    },
    rrule(prop) {
      return RRule[prop]
    },
  },
}
</script>
