<template>
  <app-side-bar
    v-model="open"
    :title="facilityId ? 'Einrichtung bearbeiten' : 'Einrichtung hinzufügen'"
    order="-2"
  >
    <template #actions>
      <input
        id="uploadPhoto"
        ref="file"
        type="file"
        accept="image/*"
        style="display: none"
        @input="choosePhoto($event)"
      />
      <v-menu absolute>
        <template #activator="{ props }">
          <v-btn
            icon
            class="mr-4"
            v-bind="props"
            data-cy="cameraButton"
          >
            <v-icon color="white"> mdi-camera </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            data-cy="uploadPicListItem"
            prepend-icon="mdi-upload"
            title="Bild der Einrichtung hochladen"
            @click="$refs.file.click()"
          />
          <v-list-item
            v-if="fileId"
            prepend-icon="mdi-delete"
            title="Bild löschen"
            @click="deleteFacilityPhoto()"
          />
        </v-list>
      </v-menu>
      <v-btn
        data-cy="saveFacilityButton"
        icon
        @click="saveFacility"
      >
        <v-icon color="white"> mdi-check </v-icon>
      </v-btn>
    </template>
    <v-container>
      <v-row>
        <v-col>
          <facility-edit
            ref="facilityAddress"
            :key="facilityId"
            :facility-id="facilityId"
            :new-parent-facility-id="parentFacilityId"
            :types="['INPATIENT', 'OUTPATIENT']"
            hide-hints
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ml-5">
          <v-btn
            data-cy="saveFacility"
            color="primary-darken"
            rounded
            size="large"
            class="px-10"
            @click="saveFacility"
          >
            Speichern
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <!-- image Facility Dialog -->

    <v-dialog
      v-model="cropImage"
      persistent
      max-width="800"
    >
      <v-card class="pa-6">
        <v-card-title class="text-h5"> Bild zuschneiden </v-card-title>
        <v-card-text>
          <cropper
            ref="cropper"
            class="upload-example-cropper"
            :src="image"
            :stencil-component="$options.components.CircleStencil"
            :stencil-props="{
              aspectRatio: 2.6,
            }"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            data-cy="abortUpload"
            color="warning-darken-1"
            variant="text"
            @click="abortUpload"
          >
            Abbrechen
          </v-btn>
          <v-spacer />
          <v-btn
            data-cy="uploadPhoto"
            color="success-darken-1"
            variant="text"
            :loading="loading"
            @click="uploadPhoto()"
          >
            Bild hochladen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </app-side-bar>
</template>
<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
  components: {
    Cropper,
  },
  data: () => ({
    loading: false,
    file: null,
    image: null,
    cropImage: false,
    fileId: '',
    imageUrl: null,
    facilityId: '',
    parentFacilityId: '',
    facilityImage: null,
    defaultImage:
      'https://images.unsplash.com/photo-1586773860418-d37222d8fce3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2073&q=80',
  }),
  computed: {
    open: {
      get() {
        return this.$sidebarStore.edit.active
      },
      set(newValue) {
        if (!newValue) {
          this.$sidebarStore.close('edit')
        }
      },
    },
  },
  mounted() {
    this.facilityId = this.$sidebarStore.edit.facility ? this.$sidebarStore.edit.facility.id : null
    this.parentFacilityId = this.$sidebarStore.edit.parentFacility
      ? this.$sidebarStore.edit.parentFacility.id
      : null
    this.facilityImage = this.$sidebarStore.edit.image || this.defaultImage
  },
  methods: {
    async saveFacility() {
      const facility = await this.$refs.facilityAddress.submit()
      if (facility) {
        if (this.fileId) {
          await this.$cms.request(this.$updateItem('facility', facility.id, { image: this.fileId }))
        }
        this.open = false
      }
    },
    choosePhoto(event) {
      const input = event.target
      if (input.files && input.files.length) {
        this.file = input.files[0]
        const reader = new FileReader()
        reader.onload = (e) => {
          this.image = e.target.result
          this.cropImage = true
        }
        reader.readAsDataURL(this.file)
      }
    },
    async uploadPhoto() {
      this.loading = true
      const { coordinates, canvas } = this.$refs.cropper.getResult()
      this.coordinates = coordinates
      const jpg = this.convertToJpgFile(canvas, 800, 0.7)

      const facilityPicture = await this.$uploadFile(jpg, jpg.name, 'facility_images')
      this.fileId = facilityPicture.id
      this.loading = false
      this.cropImage = false
      this.$refs.file.value = null
      const fileUrl = await this.fileUrl(this.fileId)
      this.imageUrl = fileUrl
    },
    convertToJpgFile(src, width, quality) {
      const resizedCanvas = document.createElement('canvas')
      const resizedContext = resizedCanvas.getContext('2d')
      const height = (src.height * width) / src.width
      resizedCanvas.width = width
      resizedCanvas.height = height
      resizedContext.drawImage(src, 0, 0, width, height)

      const image = resizedCanvas.toDataURL('image/jpeg', quality)

      const binary = atob(image.split(',')[1])
      const array = []
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }

      return new File([new Uint8Array(array)], 'facility_picture.jpg', {
        type: 'image/jpeg',
      })
    },
    async deleteFacilityPhoto() {
      if (this.fileId) {
        this.loading = true
        await this.$cms.request(this.$deleteFile(this.fileId))
        this.fileId = null
        this.loading = false
      }
    },
    abortUpload() {
      this.cropImage = false
      this.$refs.file.value = null
    },
  },
}
</script>
