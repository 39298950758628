<template>
  <v-container>
    <v-row class="text-center my-15">
      <v-col cols="12">
        <v-list bg-color="transparent">
          <v-list-item
            v-for="(item, index) in navigation"
            :key="index"
            :data-cy="item.data_cy"
            :to="item.to"
            @click="clickMenuItem(item.to)"
          >
            <v-list-item-title
              :class="{
                'text-white': true,
                disabled: item.disabled,
                'text-h1 mt-2 mb-2': $vuetify.display.smAndUp,
                'mobile mt-3 mb-3': $vuetify.display.xs,
              }"
            >
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col>
        <v-btn
          variant="text"
          color="white"
          prepend-icon="mdi-help-circle-outline"
          :size="$vuetify.display.mdAndDown ? 'large' : 'x-large'"
          @click="clickMenuItem('/contact')"
        >
          Kontakt
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          variant="text"
          color="white"
          prepend-icon="mdi-logout"
          :size="$vuetify.display.mdAndDown ? 'large' : 'x-large'"
          data-cy="logout"
          @click="clickMenuItem('/auth/logout')"
        >
          Logout
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  emits: ['toggle-menu'],

  data: () => ({
    navigation: [
      {
        icon: 'mdi-monitor-dashboard',
        title: 'Rotationsübersicht',
        to: '/dashboard/rotating',
      },
      {
        icon: 'mdi-format-list-checks',
        title: 'Rotationswünsche',
        to: '/schedule/requests',
      },
      {
        icon: 'mdi-calendar-month',
        title: 'Ausfallzeiten',
        to: '/profile/timeperiods',
      },
      {
        icon: 'mdi-cog',
        title: 'Profil bearbeiten',
        to: '/profile/overview',
      },
      /*
      {
        icon: 'mdi-book-education',
        title: 'Weiterbildungsordnung',
        to: '/profile/regulation',
        hide: context.currentUser.position && context.currentUser.position.length,
      },
      {
        icon: 'mdi-card-account-details',
        title: 'Lebenslauf',
        to: '/profile/documents',
      },
      {
        icon: 'mdi-account',
        title: 'Kontaktinformationen',
        to: '/profile/contact',
      },
      */
    ],
  }),
  computed: {},
  created() {},
  mounted() {},
  methods: {
    clickMenuItem(link) {
      this.$emit('toggle-menu')
      this.$router.push(link)
    },
  },
}
</script>
<style scoped>
.v-list-item-title {
  line-height: 120%;
  &.mobile {
    font-size: 165% !important;
  }
}
.disabled {
  opacity: 0.5;
  /* Adjust the opacity to make it look disabled */
}
</style>
