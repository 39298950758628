<template>
  <v-dialog
    v-model="acceptDataprotection"
    width="800"
    scrollable
    persistent
  >
    <v-card class="pa-5">
      <v-card-title class="pb-4 text-center text-h1">Neue Datenschutzerklärung</v-card-title>
      <v-card-text
        class="text-body-1"
        @scroll="checkScroll"
        v-html="privacyPolicy.content"
      >
      </v-card-text>
      <v-card-actions class="pt-5">
        <v-btn
          color="primary-darken"
          variant="elevated"
          rounded
          size="large"
          class="px-10 mx-auto"
          :disabled="!isScrolledDown"
          @click="accept()"
        >
          <div v-if="!isScrolledDown">Zum Akzeptieren runterscrollen</div>
          <div v-else>Akzeptieren</div>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      acceptDataprotection: false,
      isScrolledDown: false,
      privacyPolicy: [],
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const response = await this.$cms.request(
        this.$readItems('privacy_policy', {
          fields: ['id', 'content'],
          limit: 1,
          sort: '-date',
          filter: {
            users: {
              _none: {
                directus_users_id: {
                  id: {
                    _eq: this.currentUser.id,
                  },
                },
              },
            },
          },
        })
      )
      if (response.length > 0) {
        this.privacyPolicy = response[0]
        this.acceptDataprotection = true
      }
    },
    checkScroll(event) {
      if (event.target.scrollTop >= event.target.scrollHeight - event.target.offsetHeight) {
        this.isScrolledDown = true
      }
    },
    async accept() {
      const newUserPrivacyPolicy = await this.$cms.request(
        this.$createItem(
          'privacy_policy_directus_users',
          {
            directus_users_id: this.currentUser.id,
            privacy_policy_id: this.privacyPolicy.id,
            accepted_date: new Date(),
          },
          { fields: ['id'] }
        )
      )
      if (newUserPrivacyPolicy.id) {
        this.acceptDataprotection = false
        /*this.$notificationStore.set({
          text: 'Datenschutzbestimmungen akzeptiert',
          type: 'success',
        })*/
      }
    },
  },
}
</script>
